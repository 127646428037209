.model-renderer {
    width: 100%;
    height: 100vh;
    position: relative;
    perspective: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

canvas {
    outline: none;
    cursor: pointer;
    z-index: 999;
}

.model-bg-img {
    position: absolute;
    width: 75%;
    height: 75%;
    transform: rotateX(10deg);
    z-index: -999;
}

.model-loader-indicator {
    position: absolute;
    top: 50%;
    margin: 0 auto;
}

.control-button {
    position: absolute;
    bottom: 50px;
    right: 47%;
    z-index: 999999;
}
